import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.scss";
import "../App.scss";
import "../assets/scss/direction.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const Login = React.lazy(() => import("../pages/auth/Login/Login"));
import Header from "../pages/layout/header/Header";
const ServiceproviderReqList = React.lazy(() =>
  import("../pages/ServiceProviderRequest/ServiceproviderReqList")
);
const ServiceproviderReqCompanyEdit = React.lazy(() =>
  import("../pages/ServiceProviderRequest/ServiceproviderReqCompanyEdit")
);
const ServiceproviderReqIndividualEdit = React.lazy(() =>
  import("../pages/ServiceProviderRequest/ServiceproviderReqIndividualEdit")
);
const ServiceproviderReqIndividualDetail = React.lazy(() =>
  import("../pages/ServiceProviderRequest/ServiceproviderReqIndividualDetail")
);
const ServiceproviderReqCompanyDetail = React.lazy(() =>
  import("../pages/ServiceProviderRequest/ServiceproviderReqCompanyDetail")
);
const EditReqList = React.lazy(() =>
  import("../pages/EditRequest/EditReqList")
);
const EditReqIndividualDetail = React.lazy(() =>
  import("../pages/EditRequest/EditReqIndividualDetail")
);
const ManageSpList = React.lazy(() =>
  import("../pages/ManageServiceProvider/ManageSpList")
);
const ManageSpIndividualEdit = React.lazy(() =>
  import("../pages/ManageServiceProvider/ManageSpIndividualEdit")
);
const ManageSpCompanyEdit = React.lazy(() =>
  import("../pages/ManageServiceProvider/ManageSpCompanyEdit")
);
const ManageSpDetail = React.lazy(() =>
  import("../pages/ManageServiceProvider/ManageSpDetail")
);
const CreateBadgeForm = React.lazy(() =>
  import("../pages/CreateBadge/CreateBadgeForm")
);
const CategoryList = React.lazy(() =>
  import("../pages/Categories/CategoryList")
);
const ViewCategory = React.lazy(() =>
  import("../pages/Categories/ViewCategory")
);
const AddCategory = React.lazy(() => import("../pages/Categories/AddCategory"));
const UnitList = React.lazy(() => import("../pages/ManageUnit/UnitList"));
const ChooseAreaList = React.lazy(() =>
  import("../pages/ChooseArea/ChooseAreaList")
);
const CustomerList = React.lazy(() =>
  import("../pages/CustomerListing/CustomerList")
);
const CustomerEdit = React.lazy(() =>
  import("../pages/CustomerListing/CustomerEdit")
);
const SupportRequestList = React.lazy(() =>
  import("../pages/SupportRequest/SupportRequestList")
);
const Disclaimer = React.lazy(() => import("../pages/Cms/Disclaimer"));
const TermsConditions = React.lazy(() =>
  import("../pages/Cms/TermsConditions")
);
const PrivacyPolicy = React.lazy(() => import("../pages/Cms/PrivacyPolicy"));
const CancellationReason = React.lazy(() =>
  import("../pages/Cms/CancellationReason")
);
const LeftSidebar = React.lazy(() =>
  import("../pages/layout/LeftSidebar/LeftSidebar")
);
const CustomerView = React.lazy(() =>
  import("../pages/CustomerListing/CustomerView")
);
const OrderManagementList = React.lazy(() =>
  import("../pages/OrderManagement/OrderManagementList")
);
const OrderManagementListEdit = React.lazy(() =>
  import("../pages/OrderManagementEdit/OrderManagementList")
);
const OrderManagementView = React.lazy(() =>
  import("../pages/OrderManagement/OrderManagementView")
);
const OrderManagementEditView = React.lazy(() =>
  import("../pages/OrderManagementEdit/OrderManagementView")
);
const OrderManagementAdd = React.lazy(() =>
  import("../pages/OrderManagement/OrderManagementAdd")
);
const RewardOrderManagementView = React.lazy(() =>
  import("../pages/RewardOrderManagement/RewardOrderManagementView")
);

const RewardOrderManagementList = React.lazy(() =>
  import("../pages/RewardOrderManagement/RewardOrderManagementList")
);

const Faq = React.lazy(() => import("../pages/FAQ/Faq"));
const CreateSubAdmin = React.lazy(() =>
  import("../pages/CreateSubAdmin/CreateSubAdmin")
);
const TransactionsList = React.lazy(() =>
  import("../pages/transactions/TransactionsList")
);
const RewardOfferManagement = React.lazy(() =>
  import("../pages/rewardOfferManagement")
);
const DeleteAccReq = React.lazy(() => import("../pages/deleteAccountReq"));

import { currentSnackbar, changeSnackbar } from "../redux/reducers/snackbar";
import { Alert, Snackbar } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import addDeleteGetSessionStorage from "../global-modules/addDeleteGetSessionStorage";
import AdminRoute from "./adminRoute";
import OperatorRoutes from "./operatorRoutes";
import SalesRoute from "./salesRoute";
import Dashboard from "../pages/Dashboard/Dashboard";
import FullPageLoading from "../pages/LoadingComp/lodingComp";

const Routing = () => {
  const value = useSelector(currentSnackbar);
  const dispatch = useDispatch();
  const location = useLocation();
  const [roles, setRoles] = useState([]);
  const [isRoles, setIsRoles] = useState(false);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const handleStorageChange = () => {
      let data = addDeleteGetSessionStorage("fixello_data", {}, "get");
      setRoles(JSON.parse(data)?.admin_role);
      setUserData(JSON.parse(data));
      setIsRoles(true);
    };
    handleStorageChange();
  }, [location]);
  return (
    <>
      <ToastContainer />

      {isRoles ? (
        <React.Suspense fallback={<FullPageLoading />}>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/" element={<Header role={roles} data={userData} />}>
              <Route path="/" element={<LeftSidebar roles={roles} />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/transactions-list"
                  element={
                    <AdminRoute roles={roles}>
                      <TransactionsList />
                    </AdminRoute>
                  }
                />

                <Route
                  path="/service-provider-req-list"
                  element={
                    <SalesRoute roles={roles}>
                      <ServiceproviderReqList />
                    </SalesRoute>
                  }
                />
                <Route
                  path="/service-provider-req-company-edit"
                  element={
                    <OperatorRoutes roles={roles}>
                      <ServiceproviderReqCompanyEdit />
                    </OperatorRoutes>
                  }
                />
                <Route
                  path="/service-provider-req-individual-edit"
                  element={
                    <OperatorRoutes roles={roles}>
                      <ServiceproviderReqIndividualEdit />
                    </OperatorRoutes>
                  }
                />
                <Route
                  path="/service-provider-req-individual-detail/:id"
                  element={
                    <SalesRoute roles={roles}>
                      <ServiceproviderReqIndividualDetail />
                    </SalesRoute>
                  }
                />
                <Route
                  path="/service-provider-req-company-detail/:id"
                  element={
                    <SalesRoute roles={roles}>
                      <ServiceproviderReqCompanyDetail />
                    </SalesRoute>
                  }
                />
                <Route path="/edit-req-list" element={<EditReqList />} />
                <Route
                  path="/edit-req-service-provider-detail/:id"
                  element={
                    <SalesRoute roles={roles}>
                      <EditReqIndividualDetail />
                    </SalesRoute>
                  }
                />
                <Route
                  path="/manage-sp-list"
                  element={
                    // <SalesRoute roles={roles}>
                    <ManageSpList role={roles} />
                    // </SalesRoute>
                  }
                />
                <Route
                  path="/manage-sp-individual-edit"
                  element={
                    <SalesRoute roles={roles}>
                      <ManageSpIndividualEdit />
                    </SalesRoute>
                  }
                />
                <Route
                  path="/manage-sp-company-edit"
                  element={
                    <SalesRoute roles={roles}>
                      <ManageSpCompanyEdit />
                    </SalesRoute>
                  }
                />
                <Route
                  path="/manage-sp-detail/:id"
                  element={
                    // <SalesRoute roles={roles}>
                    <ManageSpDetail role={roles} />
                    // </SalesRoute>
                  }
                />
                {/* <Route path="/create-plan" element={<CreatePlaneForm />} /> */}
                <Route
                  path="/create-badge"
                  element={
                    <AdminRoute roles={roles}>
                      <CreateBadgeForm />
                    </AdminRoute>
                  }
                />
                {/* <Route
              path="/subscription-list"
              element={<SubscriptionManageList />}
            /> */}
                <Route
                  path="/category-list"
                  element={
                    <AdminRoute roles={roles}>
                      <CategoryList />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/category-add"
                  element={
                    <AdminRoute roles={roles}>
                      <AddCategory />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/category-view/:id"
                  element={
                    <AdminRoute roles={roles}>
                      <ViewCategory />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/category-add/:id"
                  element={
                    <AdminRoute roles={roles}>
                      <AddCategory />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/unit-list"
                  element={
                    <AdminRoute roles={roles}>
                      <UnitList />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/choose-area-list"
                  element={
                    <AdminRoute roles={roles}>
                      <ChooseAreaList />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/customer-list"
                  element={
                    <AdminRoute roles={roles}>
                      <CustomerList />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/customer-edit/:id"
                  element={
                    <AdminRoute roles={roles}>
                      <CustomerEdit />
                    </AdminRoute>
                  }
                />

                <Route
                  path="/delete-request-list"
                  element={
                    <AdminRoute roles={roles}>
                      <DeleteAccReq />{" "}
                    </AdminRoute>
                  }
                />

                <Route
                  path="/support-request-list"
                  element={
                    <AdminRoute roles={roles}>
                      <SupportRequestList />{" "}
                    </AdminRoute>
                  }
                />
                <Route
                  path="/end-user-license-agreement"
                  element={
                    <AdminRoute roles={roles}>
                      <Disclaimer />{" "}
                    </AdminRoute>
                  }
                />
                <Route
                  path="/terms-conditions"
                  element={
                    <AdminRoute roles={roles}>
                      <TermsConditions />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <AdminRoute roles={roles}>
                      <PrivacyPolicy />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/cancellation-reason"
                  element={
                    <AdminRoute roles={roles}>
                      <CancellationReason />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/customer-listing"
                  element={
                    <AdminRoute roles={roles}>
                      <CustomerList />
                    </AdminRoute>
                  }
                />
                <Route path="/customer-view/:id" element={<CustomerView />} />
                <Route
                  path="/order-management-list"
                  element={
                    <OperatorRoutes roles={roles}>
                      <OrderManagementList />
                    </OperatorRoutes>
                  }
                />
                {/* <Route
              path="/order-management-list-edit"
              element={
                <OperatorRoutes roles={roles}>
                  <OrderManagementListEdit />
                </OperatorRoutes>
              }
            /> */}
                <Route
                  path="/order-management-view/:id"
                  element={
                    <OperatorRoutes roles={roles}>
                      <OrderManagementView />
                    </OperatorRoutes>
                  }
                />
                {/* <Route
              path="/order-management-edit-view/:id"
              element={
                <OperatorRoutes roles={roles}>
                  <OrderManagementEditView />
                </OperatorRoutes>
              }
            /> */}
                <Route
                  path="/reward-order-management-list"
                  element={
                    <OperatorRoutes roles={roles}>
                      <RewardOrderManagementList />
                    </OperatorRoutes>
                  }
                />
                <Route
                  path="/reward-offer-management"
                  element={
                    <OperatorRoutes roles={roles}>
                      <RewardOfferManagement />
                    </OperatorRoutes>
                  }
                />

                <Route
                  path="/reward-order-management-view/:booking_id"
                  element={
                    <OperatorRoutes roles={roles}>
                      <RewardOrderManagementView />
                    </OperatorRoutes>
                  }
                />
                {/* <Route
              path="/reward-order-management-edit"
              element={
                <OperatorRoutes roles={roles}>
                  <RewardOrderManagementEdit />
                </OperatorRoutes>
              }
            /> */}
                <Route
                  path="/order-management-edit/:booking_id"
                  element={
                    <OperatorRoutes roles={roles}>
                      <OrderManagementAdd />
                    </OperatorRoutes>
                  }
                />
                <Route
                  path="/order-add"
                  element={
                    <OperatorRoutes roles={roles}>
                      <OrderManagementAdd />
                    </OperatorRoutes>
                  }
                />
                <Route
                  path="/faq"
                  element={
                    <AdminRoute roles={roles}>
                      <Faq />
                    </AdminRoute>
                  }
                />
                <Route
                  path="/create-sub-admin"
                  element={
                    <AdminRoute roles={roles}>
                      <CreateSubAdmin />
                    </AdminRoute>
                  }
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      ) : null}

      {value?.isOpen ? (
        <Snackbar
          open={value?.isOpen}
          vertcle="top"
          horizontal="right"
          onClose={() => {
            dispatch(changeSnackbar({ ...value, isOpen: false }));
          }}
          autoHideDuration={5000}
        >
          <Alert
            onClose={() => {
              dispatch(changeSnackbar({ ...value, isOpen: false }));
            }}
            severity={value?.state}
          >
            {value?.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default Routing;
